export function toDate (sdate) {
  if (!sdate) { return '' }
  if (typeof sdate === 'object') {
    sdate = toDatetime(sdate)
  }
  var rs = sdate.split(' ')
  if (rs.length > 0) { return rs[0] }
  return ''
}
export function toDatetime (sdate) {
  if (!sdate) { sdate = new Date() }
  var datetime = new Date()
  datetime.setTime(sdate.getTime())
  var year = datetime.getFullYear()
  var month = datetime.getMonth() + 1 < 10 ? '0' + (datetime.getMonth() + 1) : datetime.getMonth() + 1
  var date = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate()
  var hour = datetime.getHours() < 10 ? '0' + datetime.getHours() : datetime.getHours()
  var minute = datetime.getMinutes() < 10 ? '0' + datetime.getMinutes() : datetime.getMinutes()
  var second = datetime.getSeconds() < 10 ? '0' + datetime.getSeconds() : datetime.getSeconds()
  return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
}

export function toDecimal (num, scale) {
  var f = parseFloat(num)
  if (isNaN(f)) {
    return 0
  }
  var s = 100
  if (scale !== undefined) {
    if (!isNaN(scale)) {
      if (scale >= 0) {
        s = Math.pow(10, scale)
      } else {
        s = 10000
      }
    }
  }
  f = Math.round(f * s) / s
  return f
}

/* 数字 格式化 */
export function numberFormatter (num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

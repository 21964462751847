// 买方定向订单
export default [
  // 定向订单列表
  {
    path: '/center/buyer/reverse/list',
    component: () => import('@/views/center/buyer/reverse/list.vue')
  },
  // 定向订单详情
  {
    path: '/center/buyer/reverse/detail/:id',
    component: () => import('@/views/center/buyer/reverse/detail.vue')
  },
  // 定向合同
  {
    path: '/center/buyerreverse/procurementContractList',
    component: () => import('@/views/center/buyer/procurementContractList.vue?v=1')
  },
  // 合同明细
  {
    path: '/center/buyer/contractDetail',
    component: () => import('@/views/center/buyer/contractDetail.vue')
  },
  // 过户单
  {
    path: '/center/buyerreverse/transferGoodsBill',
    component: () => import('@/views/center/buyer/transferGoods-bill.vue?v=1')
  },
  // 提货单
  {
    path: '/center/buyerreverse/receiveGoodsBill',
    component: () => import('@/views/center/buyer/receiveGoods-bill.vue?v=1')
  }
]

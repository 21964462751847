export default {
  dictValueList (state) {
    return state.dictValueList
  },
  dictValue (state) {
    return state.dictValue
  },
  dictValueTotal (state) {
    return state.dictValueTotal
  }
}

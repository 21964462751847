// 卖方
export default [
  // 定向订单列表
  {
    path: '/center/seller/reverse/list',
    component: () => import('@/views/center/seller/reverse/list.vue')
  },
  // 定向合同添加
  {
    path: '/center/seller/reverse/add',
    component: () => import('@/views/center/seller/reverse/add.vue')
  },
  // 定向合同详情
  {
    path: '/center/seller/reverse/detail/:id',
    component: () => import('@/views/center/seller/reverse/detail.vue')
  },
  // 定向合同
  {
    path: '/center/sellerreverse/salesContractList',
    component: () => import('@/views/center/seller/salesContractList.vue?v=1')
  },
  // 合同明细
  {
    path: '/center/seller/contractDetail',
    component: () => import('@/views/center/seller/contractDetail.vue')
  },
  // 过户单
  {
    path: '/center/sellerreverse/transferGoodsBill',
    component: () => import('@/views/center/seller/transferGoods-bill.vue?v=1')
  },
  // 提货单
  {
    path: '/center/sellerreverse/receiveGoodsBill',
    component: () => import('@/views/center/seller/receiveGoods-bill.vue?v=1')
  }
]

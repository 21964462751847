import storage from './Storage'

/**
 * 用户对象
 */
const LoginUser = {
  /**
   * 名称
   */
  name: null,
  /**
   * 手机号码
   */
  mobile: null,

  scompanyname: null,
  /**
   * 详情
   */
  detail: null
}

export default {
  /**
   * 是否登录
   * @return {boolean} true-登录 false-未登录
   */
  isLogin () {
    return this.getUser().name !== null
  },
  /**
   * 进入登录页面
   *
   * @param {Router} $router 路由对象
   * @param {boolean} rememberFrom 是否记录从哪里来
   */
  toLogin ($router, rememberFrom = false) {
    if (this.isLogin()) {
      return
    }
    if (rememberFrom) {
      this.setFrom($router.currentRoute.fullPath)
    }
    $router.push('/login')
  },
  /**
   * 清除用户信息
   *
   * @param {function} callback 删除信息完成后执行的函数
   */
  clear (callback) {
    storage.local.remove('LOGIN_USER')
    storage.local.remove('authorization')
    storage.session.remove('LOGIN_USER')
    storage.session.remove('authorization')
    storage.session.remove('LOGIN_USER_PAGE')
    storage.session.remove('LOGIN_USER_BUTTON')
    this.name = null
    if (callback) {
      callback()
    }
  },
  /**
   * 获取登录用户信息
   * @return {Object} 登录对象
   */
  getUser () {
    return storage.local.get('LOGIN_USER') || LoginUser
  },
  /**
   * 公司名称
   * @param {String} scompanyname 公司名称
   */
  set scompanyname (scompanyname) {
    const user = this.getUser() || LoginUser
    user.scompanyname = scompanyname
    storage.local.set('LOGIN_USER', user)
  },
  /**
   * 获取公司名称
   * @return {String} 公司名称
   */
  get scompanyname () {
    const user = this.getUser()
    if (user !== null) {
      return user.scompanyname
    }
    return null
  },
  /**
   * 设置手机号码
   * @param {String} mobile 手机号码
   */
  set mobile (mobile) {
    const user = this.getUser() || LoginUser
    user.mobile = mobile
    storage.local.set('LOGIN_USER', user)
  },
  /**
   * 获取手机号码
   * @return {String} 手机号码
   */
  get mobile () {
    const user = this.getUser()
    if (user !== null) {
      return user.mobile
    }
    return null
  },
  /**
   * 设置名称
   * @param {String} name 名称
   */
  set name (name) {
    const user = this.getUser() || LoginUser
    user.name = name
    storage.local.set('LOGIN_USER', user)
  },
  /**
   * 获取名称
   * @return {String} 名称
   */
  get name () {
    const user = this.getUser()
    if (user !== null) {
      return user.name
    }
    return null
  },
  /**
   * 设置用户详情
   *
   * @param {Object} detail 用户信息对象
   */
  set detail (detail) {
    const user = this.getUser() || LoginUser
    user.detail = detail
    storage.local.set('LOGIN_USER', user)
  },
  /**
   * 获取用户详情信息
   * @return {Object} 用户详情
   */
  get detail () {
    const user = this.getUser()
    if (user !== null) {
      return user.detail
    }
    return null
  },
  /**
   * 设置来源地址
   * @param {String} path 路由地址
   */
  setFrom (path) {
    storage.local.set('LOGIN_USER_FROM', path)
  },
  /**
   * 获取来源地址
   * @return {String} 来源路由地址
   */
  getFrom () {
    return storage.local.get('LOGIN_USER_FROM')
  },
  /**
   * 删除来源路由地址
   */
  removeFrom () {
    storage.local.remove('LOGIN_USER_FROM')
  }
}

/**
 * @name 全局非弹框提示
 * **/
import {
  Message, Notification
} from 'element-ui'

const defaultCfg = {
  offset: 90,
  position: 'top-right',
  title: '提示',
  showClose: true
}
export default {
  // 成功提示
  success (tip = '') {
    Message({
      ...defaultCfg,
      message: tip || '操作成功',
      type: 'success'
    })
  },
  // 失败提示
  error (tip = '', res = {}) {
    let str = ''
    if (res && res.data && res.data.message) {
      str = res.data.message
    } else if (res && res.message) {
      str = res.message
    } else if (res.msg && res.msg !== '') {
      str = res.msg
    }
    Message({
      ...defaultCfg,
      message: `${tip + str}`,
      type: 'error'
    })
  },

  warning (tip = '', res = {}) {
    let str = ''
    if (res && res.data && res.data.message) {
      str = res.data.message
    } else if (res && res.message) {
      str = res.message
    } else if (res.msg && res.msg !== '') {
      str = res.msg
    }
    Message({
      ...defaultCfg,
      message: `${tip + str}`,
      type: 'warning'
    })
  },

  setRules (comForm) {
    const obj = {}
    for (const key in comForm) {
      obj[key] = []
      obj[key][0] = { required: true, message: `请输入${comForm[key]}`, trigger: 'blur' }
    }
    return obj
  }
}

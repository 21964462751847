import Vue from 'vue'
import Vuex from 'vuex'

import receiveDetail from './receiveDetail'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    roleValue: {},
    authtatus: {},
    compareNum: 0,
    readMessage: 0
  },
  mutations: {
    setRoleValue (state, roleValue) {
      state.roleValue = roleValue
    },
    setAuthtatus (state, authtatus) {
      state.authtatus = authtatus
    },
    setCompareNum (state, compareNum) {
      state.compareNum = compareNum
    },
    setReadMessage (state, readMessage) {
      state.readMessage = readMessage
    }
  },
  getters: {
    getRoleValue: state => state.roleValue,
    getAuthtatus: state => state.authtatus,
    getCompareNum: state => state.compareNum,
    getReadMessage: state => state.readMessage
  },
  modules: {
    receiveDetail
  }
})

export default store

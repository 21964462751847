export default {
  setDictValueList (state, payload) {
    state.dictValueList = [ ...payload ]
  },
  setDictValue (state, payload) {
    state.dictValue = { ...payload }
  },
  setDictValueTotal (state, payload) {
    state.dictValueTotal = payload
  }
}

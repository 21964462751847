export default {
  /**
   * sessionStorage存储
   */
  session: {
    set (key, value) {
      if (typeof (value) === 'string') {
        sessionStorage.setItem(key, value)
      } else {
        sessionStorage.setItem(key, JSON.stringify(value))
      }
    },
    get (key) {
      const v = sessionStorage.getItem(key)
      if (v === null || undefined === v) {
        return null
      }
      if (v.startsWith('{') || v.startsWith('[')) {
        return JSON.parse(v)
      }
      return v
    },
    remove (key) {
      sessionStorage.removeItem(key)
    }
  },
  /**
   * 本地存储
   */
  local: {
    set (key, value) {
      if (typeof (value) === 'string') {
        localStorage.setItem(key, value)
      } else {
        localStorage.setItem(key, JSON.stringify(value))
      }
    },
    get (key) {
      const v = localStorage.getItem(key)
      if (v === null || undefined === v) {
        return null
      }
      if (v.startsWith('{') || v.startsWith('[')) {
        return JSON.parse(v)
      }
      return v
    },
    remove (key) {
      localStorage.removeItem(key)
    }
  }
}
